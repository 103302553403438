<template>
  <ion-page>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Nuovo intervento</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-grid>
        <form @submit.prevent="creaIntervento()">
          <ion-list>
            <ion-row>
              <ion-col size="12">
                <ion-item>
                  <ion-label>
                    Data
                    <ion-text color="danger"><strong>*</strong></ion-text>
                  </ion-label>
                  <ion-datetime
                    name="date"
                    display-format="DD/MM/YYYY"
                    picker-format="DD/MM/YYYY"
                    slot="end"
                    doneText="OK"
                    cancelText="Cancella"
                    v-model="intervento.data"
                  ></ion-datetime>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row v-if="ticketCommessa.length != 0">
              <ion-col size="12">
                <ion-item>
                  <ion-label> Ticket </ion-label>
                  <ion-select
                    value="tickets"
                    interface="action-sheet"
                    cancelText="Annulla"
                    v-model="intervento.ticket"
                  >
                    <ion-select-option
                      v-for="ticket in ticketCommessa"
                      :key="ticket.tickets_id"
                      :value="ticket.tickets_id"
                    >
                      {{ ticket.tickets_subject }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label>
                    Ora inizio
                    <ion-text color="danger"><strong>*</strong></ion-text>
                  </ion-label>
                  <ion-datetime
                    name="start_time"
                    display-format="HH:mm"
                    picker-format="HH:mm"
                    value="1990-02-19T07:43Z"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="intervento.ora_inizio"
                  ></ion-datetime>
                </ion-item>
              </ion-col>
              <ion-col>
                <ion-item>
                  <ion-label>
                    Ora fine
                    <ion-text color="danger"><strong>*</strong></ion-text>
                  </ion-label>
                  <ion-datetime
                    name="end_time"
                    display-format="HH:mm"
                    picker-format="HH:mm"
                    value="1990-02-19T07:43Z"
                    slot="end"
                    doneText="OK"
                    cancelText="Annulla"
                    v-model="intervento.ora_fine"
                  ></ion-datetime>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="12">
                <ion-item>
                  <ion-label>
                    Tecnici
                    <ion-text color="danger"><strong>*</strong></ion-text>
                  </ion-label>
                  <ion-select
                    name="tecnici"
                    multiple="true"
                    cancel-text="Annulla"
                    ok-text="Conferma"
                    v-model="intervento.tecnici"
                  >
                    <ion-select-option
                      v-for="tecnico in tecnici"
                      :key="tecnico.users_id"
                      :value="tecnico.users_id"
                    >
                      {{
                        tecnico.users_first_name + " " + tecnico.users_last_name
                      }}
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label> Tempo di viaggio (min) </ion-label>
                  <ion-input
                    type="text"
                    placeholder="35"
                    v-model="intervento.tempo_viaggio"
                    class="ion-text-right"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label> Ore da fatturare </ion-label>
                  <ion-input
                    type="number"
                    placeholder="2"
                    step="any"
                    v-model="intervento.ore_fatturabili"
                    class="ion-text-right"
                  ></ion-input>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label>
                    Stato lavori
                    <ion-text color="danger"><strong>*</strong></ion-text>
                  </ion-label>
                  <ion-select value="tickets" v-model="intervento.stato_lavori">
                    <ion-select-option value="1">
                      Lavoro terminato
                    </ion-select-option>
                    <ion-select-option value="2">
                      Lavoro da terminare
                    </ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="floating">
                    Materiale utilizzato
                  </ion-label>
                  <ion-textarea
                    type="text"
                    rows="3"
                    v-model="intervento.materiale_utilizzato"
                  >
                  </ion-textarea>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col>
                <ion-item>
                  <ion-label position="floating">Descrizione</ion-label>
                  <ion-textarea
                    type="text"
                    rows="3"
                    v-model="intervento.descrizione"
                  >
                  </ion-textarea>
                </ion-item>
              </ion-col>
            </ion-row>

            <ion-row>
              <ion-col size="6">
                <ion-button
                  color="danger"
                  size="default"
                  expand="full"
                  @click="closeModal"
                >
                  Chiudi
                </ion-button>
              </ion-col>
              <ion-col size="6">
                <ion-button
                  color="success"
                  size="default"
                  type="submit"
                  expand="full"
                >
                  Invia
                </ion-button>
              </ion-col>
            </ion-row>
          </ion-list>
        </form>
      </ion-grid>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonContent,
  IonHeader,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonButton,
  modalController,
  IonGrid,
  IonRow,
  IonCol,
  IonList,
  IonItem,
  IonLabel,
  IonDatetime,
  IonTextarea,
  IonSelect,
  IonSelectOption,
  IonInput,
  IonText,
  alertController,
} from "@ionic/vue";
import { defineComponent, ref, reactive, onMounted } from "vue";

import axios from "axios";

import { getCustomers } from "../services/localbase";
import utils from "../services/utils";
import { openToast } from "../services/toast";

import apiInterventi from "../services/interventi";
import apiTickets from "../services/ticket";
import apiTecnici from "../services/tecnici";
import apiAutomezzi from "../services/automezzi";

export default defineComponent({
  name: "Modal",
  props: {
    cliente: {
      type: Object,
      required: true,
    },
    impianti: {
      type: Object,
      required: true,
    },
  },
  components: {
    IonPage,
    IonContent,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonBackButton,
    IonGrid,
    IonRow,
    IonCol,
    IonList,
    IonItem,
    IonLabel,
    IonDatetime,
    IonTextarea,
    IonSelect,
    IonSelectOption,
    IonInput,
    IonText,
  },
  setup(props, context) {
    const start_date = new Date();
    const end_hour = new Date();
    const start_hour = new Date(end_hour);
    start_hour.setHours(start_hour.getHours() - 1);

    const userID = JSON.parse(localStorage.getItem("userInfo")).users_id; //set userID in operatore field
    const intervento = reactive({
      operatore: "",
      data: start_date.toISOString(),
      cliente: "",
      impianto: "",
      ora_inizio: start_hour.toISOString(),
      ora_fine: end_hour.toISOString(),
      tecnici: [],
      tempo_viaggio: "",
      ticket: "",
      // km_percorsi: "",
      // conducente: "",
      // automezzo: "",
      stato_lavori: "",
      materiale_utilizzato: "",
      descrizione: "",
      ore_fatturabili: "",
    });

    const tecnici = ref([]);
    const conducente = ref([]);
    const automezzi = ref([]);
    const ticketCommessa = ref([]);

    const customersDB = ref([]);

    const successResponse = ref(false);

    function dateFormat(date) {
      const dateFormatted = new Date(date);
      return `${dateFormatted.getDate()}/${
        dateFormatted.getMonth() + 1
      }/${dateFormatted.getFullYear()}`;
    }
    /**
     *
     * Close modal after form data submit
     *
     */
    async function closeModalOnSubmit(successResponse, res) {
      if (successResponse.value) {
        await modalController.dismiss(res);
      } else {
        await modalController.dismiss();
      }
    }

    /**
     *
     * Get clienti from localDB
     *
     */
    async function getClienti() {
      const res = await getCustomers();
      res.forEach((cliente) => {
        customersDB.value.push(cliente);
      });
      return customersDB;
    }
    getClienti();

    /**
     *
     * Create new intervento (required field ?? )
     * Set intervento_operatore = to ID of logged user
     *
     */
    function creaIntervento() {
      intervento.operatore = userID; //set operatore/referente as logged user id

      //If one of the required field is empty show danger toast else send data
      //convert data to ISO format in Date format
      const ora_inizio = new Date(intervento.ora_inizio);
      const ora_fine = new Date(intervento.ora_fine);

      const checkTecniciLoggato = intervento.tecnici.includes(userID);
      if (!checkTecniciLoggato && intervento.tecnici.length !== 0) {
        openToast(
          "Non puoi creare un intervento senza essere tra i tecnici selezionati",
          "danger"
        );
      } else if (ora_inizio > ora_fine) {
        openToast(
          "L'ora inizio non può essere maggiore dell'ora fine",
          "danger"
        );
      } else if (
        !intervento.data ||
        !intervento.ora_inizio ||
        !intervento.ora_fine ||
        !intervento.stato_lavori ||
        //!intervento.conducente ||
        //!intervento.automezzo ||
        intervento.tecnici.length == 0
      ) {
        openToast("Tecnici presenti e stato lavori sono obbligatori", "danger");
      } else {
        const data = new FormData();
        data.append("tickets_reports_technician", intervento.operatore); //logged user
        data.append("tickets_reports_date", utils.dateFormat(intervento.data));
        data.append("tickets_reports_customer_id", props.cliente.customers_id); //current customer detail
        data.append("tickets_reports_project_id", props.impianti.projects_id);
        data.append(
          "tickets_reports_start_time",
          utils.timeFormat(intervento.ora_inizio)
        );
        data.append(
          "tickets_reports_end_time",
          utils.timeFormat(intervento.ora_fine)
        );
        intervento.tecnici.forEach((tecnico) => {
          data.append("tickets_reports_tecnici[]", tecnico);
        });
        if (intervento.ticket) {
          data.append("tickets_reports_ticket_id", intervento.ticket);
        }
        data.append("tickets_reports_travel_time", intervento.tempo_viaggio);
        //data.append("interventi_conducente", intervento.conducente);
        //data.append("interventi_automezzo", intervento.automezzo);
        //data.append("interventi_km_percorsi", intervento.km_percorsi);
        data.append(
          "tickets_reports_billable_hours",
          intervento.ore_fatturabili
        );
        data.append("tickets_reports_stato_lavori", intervento.stato_lavori);
        data.append(
          "tickets_reports_used_equipments",
          intervento.materiale_utilizzato
        );
        data.append("tickets_reports_description", intervento.descrizione);

        apiInterventi
          .saveIntervento(data)
          .then((response) => {
            const nuovo_intervento = response.data.data[0];
            //se avevo scelto un ticket e avevo messo stato lavori = terminati devo aggiornare lo stato del ticket in Lavoro terminato
            if (
              nuovo_intervento.tickets_reports_ticket_id &&
              nuovo_intervento.tickets_reports_stato_lavori == "1"
            ) {
              apiTickets.changeTicketStatus(
                nuovo_intervento.tickets_reports_ticket_id
              );
            }
            const res = response.data.data[0];
            successResponse.value = true;
            closeModalOnSubmit(successResponse, res);
          })
          .catch((error) => {
            // handle error
            console.error(error);
            openToast("Errore durante la creazione dell'intervento", "danger");
          })
          .finally(() => {
            //console.log("fine creazione intervento");
          });
      }
    }

    /**
     *
     * Handle close modal by clicking on Chiudi button
     *
     */
    const closeModal = () => {
      modalController.dismiss();
    };

    onMounted(() => {
      //GET TECNICI
      apiTecnici
        .getTecnici()
        .then((response) => {
          tecnici.value = response;
          conducente.value = response;
        })
        .catch((error) => {
          error.value = true;
          openToast(
            "Errore durante la richiesta dei dati relativi ai tecnici",
            "danger"
          );
          console.error(error);
        })
        .finally(() => {
          //console.log("fine richiesta tecnici");
        });
      //GET TICKET FOR THIS COMMESSA
      apiTickets
        .getTicketCommessa(props.impianti.projects_id, userID)
        .then((response) => {
          ticketCommessa.value = response;
        })
        .catch((error) => {
          error.value = true;
          openToast(
            "Errore durante la richiesta dei ticket relativi alla commessa",
            "danger"
          );
          console.error(error);
        })
        .finally(() => {
          //console.log("fine richiesta ticket della commessa");
        });
      //GET AUTOMEZZI
      apiAutomezzi
        .getAutomezzi()
        .then((response) => {
          automezzi.value = response;
        })
        .catch((error) => {
          error.value = true;
          openToast(
            "Errore durante la richiesta dei dati relativi agli automezzi",
            "danger"
          );
          console.error(error);
        })
        .finally(() => {
          //console.log("fine richiesta automezzi");
        });
    });

    return {
      intervento,
      creaIntervento,
      closeModal,
      customersDB,
      tecnici,
      conducente,
      automezzi,
      ticketCommessa,
      //isRequired,
      //onSubmit,
    };
  },
});
</script>

<style scoped>
.select100 {
  max-width: 100% !important;
}

.error_message {
  color: #eb445a;
}
.danger {
  color: #eb445a !important;
}

/*.alert_confirm {
  --width: 30%;
  --height: 150px;
}

.sc-ion-alert-md-h {
  --width: 330px;
}*/

.alert-wrapper .ion-overlay-wrapper .sc-ion-alert-md {
  width: 100% !important;
}

.alert-wrapper {
  width: 90% !important;
}
</style>
