<template>
  <ion-page>
    <!--<ion-header>
      <ion-toolbar color="primary">
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title>Dettaglio impianto</ion-title>
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true" class="ion-padding">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-title size="large">Dettaglio impianto</ion-title>
        </ion-toolbar>
      </ion-header>-->
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button></ion-back-button>
        </ion-buttons>
        <ion-title color="dark">Dettaglio impianto</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content :fullscreen="true">
      <ion-header collapse="condense">
        <ion-toolbar>
          <ion-buttons slot="start">
            <ion-back-button></ion-back-button>
          </ion-buttons>
          <ion-title size="large" color="dark">Dettaglio impianto</ion-title>
        </ion-toolbar>
      </ion-header>

      <div v-if="loading" class="loading_spinner">
        <ion-spinner name="crescent" color="dark"></ion-spinner>
      </div>
      <div v-else>
        <div class="impianto_container">
          <div class="impianto_informations">
            <div class="item">
              <div class="label">Nome</div>
              <div class="field">
                {{ impianto.projects_name }}
              </div>
            </div>

            <div class="item">
              <div class="label">Tipologia</div>
              <div class="field">
                {{ impianto.projects_category_value || " - " }}
              </div>
            </div>

            <div class="item">
              <div class="label">Stato</div>
              <div class="field">
                <ion-badge
                  :color="statoImpianto(impianto.projects_status_id)"
                  mode="ios"
                  class="custom_badge"
                >
                  {{ impianto.projects_status_value }}
                </ion-badge>
              </div>
            </div>

            <div class="item">
              <div class="label">Data installazione</div>
              <div class="field">
                {{ dateFormat(impianto.projects_data_installazione) }}
              </div>
            </div>

            <div class="item">
              <div class="label">Data contratto manutenzione</div>
              <div class="field">
                {{ dateFormat(impianto.projects_contratto_manutenzione_data) }}
              </div>
            </div>

            <div class="item">
              <div class="label">Data garanzia</div>
              <div class="field">
                {{ dateFormat(impianto.projects_data_garanzia) }}
              </div>
            </div>

            <div class="item item_description">
              <div class="label">Descrizione</div>
              <div class="field field_description">
                {{ impianto.projects_descriptions || "-" }}
              </div>
            </div>

            <div class="item item_description">
              <div class="label">Note</div>
              <div class="field field_description">
                {{ impianto.projects_note || "-" }}
              </div>
            </div>
          </div>
        </div>

        <div class="gallery">
          <div class="title">Galleria</div>
          <div class="gallery_container">
            <div v-if="commessa_immagini.length != 0" class="gallery_scroller">
              <div
                v-for="(img, index) in commessa_immagini"
                :key="index"
                class="thumbnail"
              >
                <ion-thumbnail>
                  <ion-img :src="img"></ion-img>
                </ion-thumbnail>
              </div>
            </div>
            <div v-else class="no_image">
              <ion-text color="danger"> Nessuna foto caricata </ion-text>
            </div>
          </div>
        </div>

        <div class="tabbed_interface">
          <ion-segment value="lavorazioni">
            <ion-segment-button
              value="lavorazioni"
              @click="handleShowLavorazioni"
            >
              <ion-label>Interventi</ion-label>
            </ion-segment-button>
            <ion-segment-button value="apparati" @click="handleShowMateriali">
              <ion-label>Apparati</ion-label>
            </ion-segment-button>
          </ion-segment>
        </div>

        <div v-show="mostraLavorazioni">
          <div class="interventi_container">
            <div v-if="loadingIntervento" class="loading_tabbed">
              <ion-spinner name="crescent" color="dark"></ion-spinner>
            </div>
            <div v-else>
              <div v-if="interventi.length != 0">
                <div
                  v-for="intervento in interventi"
                  :key="intervento.tickets_reports_id"
                  @click="openDetail(intervento.tickets_reports_id)"
                  class="intervento"
                >
                  <div class="intervento_detail">
                    <div class="intervento_title">
                      {{ dateFormat(intervento.tickets_reports_date) }}
                    </div>
                    <div class="intervento_customer">
                      Orario intervento:
                      {{ intervento.tickets_reports_start_time }} -
                      {{ intervento.tickets_reports_end_time }}
                    </div>
                    <div class="intervento_customer">
                      Tipologia:
                      {{ intervento.tickets_reports_type_value || " - " }}
                    </div>
                  </div>
                  <ion-icon
                    :icon="chevronForward"
                    class="icon_detail"
                  ></ion-icon>
                </div>
              </div>
              <div v-else class="intervento no_interventi">
                <ion-text color="danger">
                  Nessun intervento effettuato
                </ion-text>
              </div>
            </div>
          </div>
        </div>

        <div v-show="mostraMateriali">
          <div class="interventi_container">
            <div v-if="loadingIntervento" class="loading_tabbed">
              <ion-spinner name="crescent" color="dark"></ion-spinner>
            </div>
            <div v-else>
              <div v-if="apparati.length != 0">
                <div
                  v-for="apparato in apparati"
                  :key="apparato.equipments_id"
                  class="intervento"
                >
                  <div class="intervento_detail">
                    <div class="intervento_title">
                      {{ apparato.equipments_brand }}
                    </div>
                    <div class="intervento_customer">
                      {{ apparato.equipments_model }}
                    </div>
                    <div class="intervento_customer">
                      {{ apparato.equipments_serial_number }}
                    </div>
                  </div>
                </div>
              </div>
              <div v-else class="intervento no_apparati">
                <ion-text color="danger"> Nessun apparato associato </ion-text>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ion-fab horizontal="end" vertical="bottom" slot="fixed">
        <ion-fab-button color="success" @click="openModal">
          <ion-icon :icon="add"></ion-icon>
        </ion-fab-button>
      </ion-fab>
    </ion-content>
  </ion-page>
</template>

<script>
import {
  IonPage,
  IonHeader,
  IonToolbar,
  IonButtons,
  IonBackButton,
  IonTitle,
  IonContent,
  IonSpinner,
  /*IonCard,
  IonCardContent,
  /*   IonCardHeader,
  IonCardTitle,
  IonList,
  IonItem,*/
  IonLabel,
  IonText,
  IonBadge,
  IonSegment,
  IonSegmentButton,
  IonFab,
  IonFabButton,
  IonIcon,
  /*IonFab,
  IonFabList,
  IonFabButton,
  IonIcon,*/
  IonThumbnail,
  IonImg,
  modalController,
} from "@ionic/vue";
import { add, menu, construct, chevronForward } from "ionicons/icons";

import { onMounted, ref, computed } from "vue";
import { useRoute, useRouter } from "vue-router";

import apiCommesse from "../services/commesse";
import apiMateriali from "../services/materiali";
import { openToast } from "../services/toast";
import { getCustomer } from "../services/localbase";

import Modal from "../components/Modal.vue";
import Modal2 from "../components/Modal2.vue";

import moment from "moment";

export default {
  name: "CommessaDetail",
  components: {
    IonPage,
    IonHeader,
    IonToolbar,
    IonButtons,
    IonBackButton,
    IonTitle,
    IonContent,
    IonSpinner,
    /*IonCard,
    IonCardContent,
    /*     IonCardHeader,
    IonCardTitle,
    IonList,
    IonItem,*/
    IonLabel,
    IonText,
    IonBadge,
    IonSegment,
    IonSegmentButton,
    IonFab,
    IonFabButton,
    IonIcon,
    IonThumbnail,
    IonImg,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    const idCommessa = route.params.id_commessa;
    const idCliente = route.params.id;

    const loading = ref(false);
    const loadingInfoCommessa = ref(false);

    const impianto = ref([]);
    const lavorazioni = ref([]);
    const interventi = ref([]);
    const apparati = ref([]);
    //flag per visualizzare i dati nelle tab
    const mostraLavorazioni = ref(false);
    const mostraMateriali = ref(false);

    const cliente = ref([]);

    const equipmentStatus = ref("");
    const loadingIntervento = ref(false);

    const commessa_immagini = ref([]);

    /**
     *
     * Get selected customer data to use in modal info
     *
     */
    async function getCliente(idCliente) {
      const customer = await getCustomer(idCliente);
      cliente.value = customer;
    }
    getCliente(idCliente);

    /**
     *
     * Format date from YYYY/MM/DD HH:mm:ss to DD/MM/YYY
     *
     */
    function dateFormat(date) {
      if (date) {
        if (moment.isDate(new Date(date))) {
          return moment(date).format("DD/MM/YYYY");
        } else {
          return "-";
        }
      } else {
        return "-";
      }
    }

    function showCapoCommessa(impianto) {
      if (impianto.users_first_name && impianto.users_last_name) {
        return `${impianto.users_first_name} ${impianto.users_last_name}`;
      } else {
        return " - ";
      }
    }

    /**
     *
     * Set correct background for impianto status
     *
     */
    const statoImpianto = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 2) {
          //da installare
          className = "danger";
        } else if (statusId == 3) {
          //lavori in corso
          className = "info";
        } else if (statusId == 4) {
          //cancellato
          className = "dark";
        } else if (statusId == 5) {
          className = "success"; //completato
        }
        return className;
      };
    });

    const statoApparato = computed(() => {
      return (statusId) => {
        let className = "";
        if (statusId == 1) {
          //attivato
          className = "success";
          equipmentStatus.value = "Abilitato";
        } else {
          //non attivato
          className = "danger";
          equipmentStatus.value = "Non abilitato";
        }
        return className;
      };
    });

    /**
     *
     * Set correct card to show/hide on tabbed interface
     *
     */
    function handleShowLavorazioni() {
      mostraMateriali.value = false;
      mostraLavorazioni.value = true;
    }
    function handleShowMateriali() {
      mostraLavorazioni.value = false;
      mostraMateriali.value = true;
    }
    handleShowLavorazioni();

    /**
     *
     * Open modal to create new intervento
     *
     */
    async function openModal() {
      const modal = await modalController.create({
        component: Modal2, //Component to render inside ionic modal
        componentProps: {
          cliente: impianto.value.projects_customer_id,
          impianto: impianto.value.projects_id,
          ticket: impianto.value.tickets_id,
        },
      });
      modal.onDidDismiss().then((detail) => {
        //console.log(detail.data);
        if (detail.data != undefined) {
          openToast("Nuovo intervento inserito", "success");
          router.push(`/tabs/tab2/${detail.data.tickets_reports_id}`);
        }
      });
      return modal.present();
    }

    /**
     *
     * Open intervento detail page
     *
     */
    function openDetail(id) {
      router.push(`/tabs/tab3/${id}`);
    }

    onMounted(() => {
      loading.value = true;
      loadingInfoCommessa.value = true;
      /* GET INFO COMMESSA */
      apiCommesse
        .getCommessaDetail(idCommessa)
        .then((response) => {
          impianto.value = response;
          lavorazioni.value = response.projects_lavorazioni;
          //ASSEGNO IMMAGINI COMMESSA
          if (impianto.value["projects_allegati"]) {
            const allegati = JSON.parse(response.projects_allegati);

            allegati.forEach((allegato) => {
              const { path_local } = allegato;
              const fileSrc = `https://crm.sicurity.it/uploads/${path_local}`;
              commessa_immagini.value.push(fileSrc);
            });
          }
        })
        .catch((error) => {
          error.value = true;
          openToast(
            "Errore durante la richiesta dei dati dell'impianto",
            "danger"
          );
          console.error(error);
        })
        .finally(() => {
          loading.value = false;
        });
      /* GET interventi COMMESSA */
      loadingIntervento.value = true;
      apiCommesse
        .getInterventiCommessa(idCommessa)
        .then((response) => {
          //console.log(response);
          interventi.value = response;
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta degli interventi", "danger");
          console.error(error);
        })
        .finally(() => {
          loadingIntervento.value = false;
          //console.log("fine getCommessaDetail");
        });

      /* GET apparato COMMESSA */
      apiMateriali
        .getEquipments(idCommessa)
        .then((response) => {
          //console.log(response);
          apparati.value = response;
        })
        .catch((error) => {
          error.value = true;
          openToast("Errore durante la richiesta degli apparati", "danger");
          //console.error(error);
        })
        .finally(() => {
          loadingInfoCommessa.value = false;
          //console.log("fine getMaterialecommessa");
        });
    });

    return {
      loading,
      dateFormat,
      showCapoCommessa,
      impianto,
      lavorazioni,
      interventi,
      apparati,
      statoImpianto,
      statoApparato,
      equipmentStatus,
      loadingIntervento,
      openDetail,
      //flag gestione visualizzazione tab
      handleShowLavorazioni,
      handleShowMateriali,
      mostraLavorazioni,
      mostraMateriali,
      //Modale nuovo intervento
      add,
      menu,
      construct,
      openModal,
      //New field for figma layout
      chevronForward,
      commessa_immagini,
    };
  },
};
</script>

<style scoped>
.loading_spinner {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tabbed_interface {
  padding: 0 10px;
}

.impianto,
.lavorazioni,
.apparati {
  margin-bottom: 20px;
}
.impianto_codice {
  margin-bottom: 0;
}

.base_text {
  font-size: 14px;
}
.pb-0 {
  padding-bottom: 0;
}
.bg-primary {
  background: var(--ion-color-primary, #3880ff) !important;
  padding: 8px;
  color: #ffffff;
}

.card_title {
  margin: 0;
  padding: 0;
  font-size: 20px;
}

ion-item {
  font-size: 14px;
}
ion-label {
  margin: 0;
}

.font-bold {
  font-weight: 600;
}

.brand_equipment {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.custom_ion_item {
  margin-bottom: 10px;
}

/* Rules for figma design */

/* Rules for figma layout */
.no_interventi,
.no_apparati {
  display: flex !important;
  justify-content: center !important;
  padding: 30px 10px !important;
  font-size: 14px;
  font-weight: 500;
  line-height: 17px;
  color: #ff0000;
}

.impianto_container {
  width: 100%;
  margin-top: 16px;
  padding: 10px;
}

.impianto_informations {
  width: 100%;
  border-radius: 4px;
  background: #f8f8f8;
}
.impianto_informations .item {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 18px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}
.impianto_informations .item:last-of-type {
  border: none;
}
.impianto_informations .item_description {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  padding: 18px;
  border-bottom: 1px solid rgba(153, 153, 153, 0.2);
}

.impianto_informations .item .label {
  font-size: 12px;
  line-height: 14px;
}
.impianto_informations .item .field {
  font-size: 12px;
  line-height: 14px;
  font-weight: 500;
}
.impianto_informations .item .field_description {
  margin-top: 10px;
}

.custom_badge {
  font-size: 10px;
  font-weight: 600 !important;
}

/* Rules for figma layout */
/* Commessa interventi & apparati */
.loading_tabbed {
  display: flex;
  justify-content: center;
  padding: 16px 0;
}

.interventi_container {
  width: 100%;
  padding: 8px 16px;
  margin-bottom: 16px;
}

.intervento {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-bottom: 10px;
  border-radius: 4px;
  background-color: #f8f8f8;
}
.intervento .intervento_title {
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  margin-bottom: 8px;
}
.intervento .intervento_customer {
  font-size: 12px;
  line-height: 14px;
  color: #919195;
  margin-bottom: 8px;
}
.intervento .intervento_customer:last-of-type {
  margin-bottom: 0;
}

.intervento .icon_detail {
  font-size: 18px;
}

ion-segment-button {
  --color-checked: #000000;
}

/* Gallery rules */
.gallery {
  margin: 16px;
}
.gallery .title {
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 10px;
}
.gallery .gallery_container {
  width: 100%;
  background-color: #f8f8f8;
  border-radius: 4px;
  padding: 16px;
}
.gallery_container .gallery_scroller {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  overflow-x: scroll;
}

.gallery_scroller .thumbnail {
  margin-right: 5px;
}

.gallery_scroller ion-thumbnail {
  --size: 80px;
  --border-radius: 4px;
}

.no_image ion-text {
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}
</style>